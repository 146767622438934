// _global.scss       app/styles/partials/
////////////////////////////////////////////////////////////

// Set this styling to every element
::ng-deep * {
  box-sizing: border-box;
}

// All pages have .content wrapping, setting the page width
// and centering on the page.
::ng-deep .content {
  background-color: $content-bg-clr;
  max-width: $page-max-width;
  margin: 0 auto;
}

// Set global p color
::ng-deep p {
  color: $global-p-clr;
}

// Set global a colors
::ng-deep a {
  color: $global-a-clr;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: lighten($global-a-clr, 25%);
  }
}

// General Layout
army-footer {
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  @media #{$medium-up} {
    padding-left: rem-calc(25);
    padding-right: rem-calc(25);
  }
  @media #{$large-up} {
    padding-left: rem-calc(140);
    padding-right: rem-calc(140);
  }
}
