// _buttons.scss - app/styles/partials
////////////////////////////////////////////////////////////////////////////////

// Disabling the focus outline
button:focus {
  outline: none;
}

a:focus {
  outline: none;
}

:host {
  .pill-btn-large {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(274);
    height: rem-calc(50);
    border-radius: rem-calc(25);
    border: 2px solid rgba($global-pill-clr, 0.5);
    font-family: $Montserrat;
    font-size: rem-calc(12.9);
    line-height: rem-calc(20);
    letter-spacing: rem-calc(0.5);
    color: $white;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    @media #{$large-up} {
      font-size: rem-calc(14);
      line-height: 1.4;
    }
    &:hover,
    &:focus {
      border-color: $global-pill-clr;
    }
  }
  .pill-btn-small {
    display: flex;
    align-items: center;
    float: right;
    border-radius: rem-calc(25);
    border: 2px solid rgba($global-pill-clr, 0.5);
    font-family: $Montserrat;
    font-size: rem-calc(12.9);
    line-height: rem-calc(20);
    letter-spacing: rem-calc(0.5);
    color: $white;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    padding: rem-calc(5 15);
    &:hover,
    &:focus {
      border-color: $global-pill-clr;
    }
  }
}
