// _cdk-overlay.scss - app/styles/partials
////////////////////////////////////////////////////////////////////////////////

// This guarantees that the overlay styles apply
::ng-deep .cdk-overlay-container {
  position: fixed;
  z-index: 10000;
}

::ng-deep .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 10000;
}

::ng-deep .cdk-overlay-container,
::ng-deep .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

::ng-deep .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

::ng-deep .cdk-overlay-dark-backdrop {
  background: $black-32;
}

::ng-deep .cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

::ng-deep .cdk-overlay-pane {
  max-width: 100% !important;
}
