// Industry Fonts
@each $font-face in industry-bold, industry-book, industry-light {
  @font-face {
    font-family: $font-face;
    src: url("#{$font-path}/industry/fonts/#{$font-face}.eot");
    src:
      url("#{$font-path}/industry/fonts/#{$font-face}.eot?#iefix")
        format("embedded-opentype"),
      url("#{$font-path}/industry/fonts/#{$font-face}.woff2") format("woff2"),
      url("#{$font-path}/industry/fonts/#{$font-face}.woff") format("woff"),
      url("#{$font-path}/industry/fonts/#{$font-face}.ttf") format("truetype"),
      url("#{$font-path}/industry/fonts/#{$font-face}.svg##{$font-face}")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
}
