// _fonts.scss       app/styles/mixins/
////////////////////////////////////////////////////////////
@import "https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,900,900i&display=swap";

.montserrat-regular {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.montserrat-black {
  font-family: Montserrat, sans-serif;
  font-weight: 900;
}
