/* stylelint-disable */

.mat-mdc-dialog-container {
  background-color: $transparent;
  box-shadow: none;

  // Dialog overlay
  .dialog-content {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 50%;
    max-width: rem-calc(1200);
    transform: translate(-50%);
  }

  // Button to close dialog
  .close {
    cursor: pointer;
    background-image: url("/assets/img/close-thick.svg");
    background-size: 60%;
    background-repeat: no-repeat;
    width: rem-calc(34);
    height: rem-calc(34);
    border: rem-calc(2) solid $white;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    background-position: center;
    filter: grayscale(1) brightness(5);
    top: 20%;
    right: 5%;
    box-shadow: rem-calc(0 0 12) $black-50;
    &:hover {
      opacity: 0.7;
      transition: opacity 0.5s;
    }
    @media #{$large-up} {
      top: 15%;
    }
  }

  // Background for WebGL content
  .webgl-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    max-width: rem-calc(1200);
    border: 0;
    transform: translate(-50%, -50%);
    background-image: url("/assets/img/webgl-background.png");
    background-size: 99vw 50vh;
    padding: 0% 7.5%;
    background-repeat: no-repeat;
    background-position: center;
  }

  // External Wrapper to also close dialog
  .external-wrapper {
    width: 100vw;
    height: 100vh;
    z-index: 0;
    position: absolute;
    left: -10%;
  }
}

.element-char-modal {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: $transparent;
    box-shadow: none;
  }
}
