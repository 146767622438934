/* You can add global styles to this file, and also import other style files */

$font-path: "/assets/fonts";
$cdn-font-path: "https://cdn.armygamestudio.com/branding/army";

@import "styles/setup";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// @font-face setups - See config for variables
@import "./assets/fonts/industry/index";
@import "./assets/fonts/gi/index";
