@each $font-face in gi-regular, gi-medium, gi-bold, gi-regular-Italic,
  gi-medium-Italic, gi-bold-italic
{
  @font-face {
    font-family: $font-face;
    src: url("#{$cdn-font-path}/gi-font/fonts/#{$font-face}.otf")
      format("opentype");
    src: url("#{$cdn-font-path}/gi-font/fonts/#{$font-face}.ttf")
      format("truetype");
    src: url("#{$cdn-font-path}/gi-font/fonts/#{$font-face}.woff")
      format("woff");
    src: url("#{$cdn-font-path}/gi-font/fonts/#{$font-face}.woff2")
      format("woff2");
    font-weight: normal;
    font-style: normal;
  }
}
