@import "../mixins/all";
@import "../config";

// TJM: 8/21/19
// This is being imported into the styles.scss file
// So far that's the only way we have been able to overwrite
// the material styling.

/* stylelint-disable */

stars-lessons-page,
stars-guide-page {
  .mat-mdc-tab-header {
    background-color: $deep-purple;
    display: flex;
    z-index: 100;
    @media #{$large-up} {
      top: rem-calc(100px);
      left: 0;
      right: 0;
    }
  }

  .mat-mdc-tab-body.mat-mdc-tab-body-active {
    overflow: hidden;
    height: fit-content;
  }

  .mat-mdc-tab-group {
    z-index: 0;
    position: relative;
    height: auto;
  }

  .mat-mdc-tab-labels {
    display: flex;
    justify-content: center;
  }

  .mdc-tab {
    font-family: $industry-book;
    min-width: rem-calc(72);
    @media #{$medium-up} {
      min-width: rem-calc(160);
    }
    .mdc-tab__text-label {
      color: $white-50 !important;
      font-size: rem-calc(16);
    }
    &.mdc-tab--active {
      background-color: $white-15;
      .mdc-tab__text-label {
        color: $white !important;
      }
    }
  }

  .mat-ink-bar {
    background-color: transparent !important;
    display: none;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border: none;
  }

  .mat-mdc-tab.mdc-tab {
    flex-grow: 0 !important;
  }

  .mat-mdc-tab .mdc-tab__ripple::before {
    background-color: $transparent;
  }
}
