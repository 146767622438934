// _config.scss      app/styles/
// This file is only for variables that will be used globally. Do not
// include any scss other than variables here.
/////////////////////////////////////////////////////////////////////

// Colors
$black: #000000;
$white: #ffffff;
$crusoe: #004e1c;
$azure: #009fff;
$deep-sky-blue: #00abfd;
$united-nations-blue: #098af9;
$vibrant-blue: #005eff;
$blueish-purple: #2b0c9a;
$dark-pastel-green: #00c747;
$asparagus: #6fb300;
$denim: #2882c2;
$mantis: #61cc5b;
$sprite: #70b749;
$vulcan: #0f1721;
$dark: #161a1f;
$steel-grey: #222032;
$midnight: #243040;
$thunder: #4c4c4c;
$bright-light-blue: #3bd0fa;
$fiord: #3c4e67;
$dark-royal: #2a2440;
$cornflower-blue: #211c33;
$wakanda: #270348;
$waikawa-grey: #597393;
$cadet-grey: #8e9fb4;
$purpley-grey: #92909f;
$shadow-gray: #19242682;
$extra-light-purple: #efe4f5;
$purple-mountain: #8c4dca;
$vibrant-purple: #8a1ad6;
$purple: #7866ba;
$twilight: #554b8a;
$dark-hot-pink: #d4055c;
$iron: #e6d2d2;
$wild-sand: #ededed;
$bombay: #b4b4b4;
$mindaro: #daeb6d;
$butterscotch: #fcbd43;
$violet-pink: #ff5aff;
$pink-flamingo: #ff62ff;
$army-gold: #ffcc01;
$gray: #888888;
$grayish: #d3d3d3;
$deep-purple: #3f3660;

$black-5: rgba($black, 0.05);
$black-25: rgba($black, 0.25);
$black-32: rgba($black, 0.32);
$black-50: rgba($black, 0.5);
$black-70: rgba($black, 0.7);
$white-15: rgba($white, 0.15);
$white-20: rgba($white, 0.2);
$white-50: rgba($white, 0.5);
$white-70: rgba($white, 0.7);
$fiord-70: rgba($fiord, 0.7);
$gray-50: rgba($gray, 0.5);
$vibrant-purple-50: rgba($vibrant-purple, 0.5);

$transparent: rgba($black, 0);

// Set Menu Colors
///////////////////////////////////////////////////////////////////
$menu-bg-clr: $dark-royal;
$menu-link-clr: $white-70;
$menu-link-active-clr: $white;
$menu-link-border-clr: $bright-light-blue;
$menu-webplayer-border: $bright-light-blue;
$menu-hamburger-clr: $bright-light-blue;

// Global Colors
///////////////////////////////////////////////////////////////////
$content-bg-clr: $white;
$content-background-dots-clr: $bright-light-blue;
// ## Body background color is set in main index.html ( background-color: #161A1F; )
// ## No variables allowed
$global-p-clr: $purpley-grey;
$global-a-clr: $bright-light-blue;
$global-pill-clr: $bright-light-blue;

// Fonts - @font-face is setup in styles/global/_fonts.scss
///////////////////////////////////////////////////////////////////
$industry-bold: "industry-bold", sans-serif;
$industry-book: "industry-book", sans-serif;
$industry-light: "industry-light", sans-serif;
$Montserrat: "Montserrat", sans-serif;
// G.I.
$gi-regular: "gi-regular", sans-serif;
$gi-medium: "gi-medium", sans-serif;
$gi-bold: "gi-bold", sans-serif;
///////////////////////////////////////////////////////////////////

// Page Width
$page-max-width: rem-calc(1440);
$content-max-width: rem-calc(1160);

// Header Height
$header-height: rem-calc(80);

// App Button Width
$app-button-width: rem-calc(259);
